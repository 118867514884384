import { Button, ButtonGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import { useEffect, useState } from "react";
import moment from "moment";
import { useRecalculatePortfolio } from "../../../hooks/useRecalculatePortfolio";
import { useMessages } from "../../../hooks/useMessages";
import { useBusy } from "../../../hooks/useBusy";
type RangeSelectorProps = {
    name: string;
    value: string;
    date: string;
};

const RangeSelector = () => {
    let { modelId = "" } = useParams();
    const { useModelQuery } = useModels();
    const modelQuery = useModelQuery(modelId || "");
    const model = modelQuery.data;
    const [selectedPeriod, setSelectedPeriod] = useState<string>("");
    const { addErrorMessage, addInfoMessage } = useMessages();
    const { setIsBusy } = useBusy();
    const periods = [
        {
            name: "6M",
            value: "6M",
            date: moment().subtract(6, "months").format("YYYY-MM-DD"),
        },
        {
            name: "YTD",
            value: "YTD",
            date: `${new Date().getFullYear()}-01-01`,
        },
        {
            name: "1Y",
            value: "1Y",
            date: moment().subtract(1, "years").format("YYYY-MM-DD"),
        },
        {
            name: "2Y",
            value: "2Y",
            date: moment().subtract(2, "years").format("YYYY-MM-DD"),
        },
        {
            name: "3Y",
            value: "3Y",
            date: moment().subtract(3, "years").format("YYYY-MM-DD"),
        },
        {
            name: "5Y",
            value: "5Y",
            date: moment().subtract(5, "years").format("YYYY-MM-DD"),
        },
    ];

    useEffect(() => {
        if (model && model.backtest?.period) {
            setSelectedPeriod(model.backtest?.period);
        }
    }, [model]);

    const { recalculatePortfolio } = useRecalculatePortfolio({
        model,
        onBeforeMutation: () => {
            addInfoMessage(
                "Info",
                "Your portfolio is being updated. Please wait"
            );
            setIsBusy(true);
        },
        handleError: () => {
            addErrorMessage(
                "Error",
                "Your portfolio has not been updated. Please try again later"
            );
            setIsBusy(false);
        },
        handleSuccess: () => {
            if (model) {
                //   const updatedModel = {
                //     modelId: model.modelId,
                //     userId: model.userId,
                //     backtest: {
                //         period: selectedPeriod
                //     }
                // } as FactorModelType;
                // updateModel(updatedModel);
            }
            addInfoMessage(
                "Portfolio updated",
                "Your portfolio has been successfully updated"
            );
            setIsBusy(false);
        },
    });

    const onPeriodChange = (period: RangeSelectorProps) => {
        if (model) {
            setSelectedPeriod(period.value);
            recalculatePortfolio({
                startCash: model.backtest?.startCash,
                leverage: model.backtest?.leverage,
                longsShare: model.backtest?.longsShare,
                shortsShare: model.backtest?.shortsShare,
                numberOfStocks: model.backtest?.numberOfStocks,
                backtestPeriod: period.date,
                period: period.value,
                portfolioConstruction:
                    model.backtest?.portfolioConstruction || "LongShort",
                ascending: model.backtest?.ascending,
            });
        }
    };

    return (
        <ButtonGroup>
            {periods.map((period: RangeSelectorProps) => (
                <Button
                    key={period.value}
                    variant={
                        selectedPeriod === period.value
                            ? "primary"
                            : "secondary"
                    }
                    onClick={() => onPeriodChange(period)}
                >
                    {period.name}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default RangeSelector;
