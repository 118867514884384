import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useFactors } from "../../../hooks/query/useFactors";
import { useModels } from "../../../hooks/query/useModel";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { padNumber, toFixed } from "../../../utils/utils";
import { AiTwotoneInfoCircle } from "react-icons/ai";

const FactorInfo = ({ modelId }: { modelId: string }) => {
    const { useModelQuery } = useModels();
    const { useFactorsListQuery, useFactorDetailsQuery } = useFactors();
    const modelQuery = useModelQuery(modelId || "");
    const factorsQuery = useFactorsListQuery();
    const factorsDetailsQuery = useFactorDetailsQuery();

    if (modelQuery.data && factorsQuery.data && factorsDetailsQuery.data) {
        const factor = Object.keys(modelQuery.data.factor || {})[0];
        const strategy = factorsQuery.data.find(
            (strategy) => strategy.PK === modelQuery.data.strategyId
        );
        const factorMetrics = strategy?.factors.find(
            (f) => f.Factor === factor
        );
        const factorDetail = factorsDetailsQuery.data.find(
            (f) => f.Feature === factor
        );

        return (
            <>
                <div>
                    <h5>Stats</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Regarding the theory, the Signal
                                                Strength (T-statistic/T-test)
                                                ratio that is greater than 2.0,
                                                or less than -2.0 is
                                                statistically significant.
                                                However, our application changes
                                                negative values to plus values
                                                for simplicity.
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            Signal Strength (T-Test)
                                            <AiTwotoneInfoCircle />
                                        </span>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    {padNumber(
                                        toFixed(
                                            factorMetrics?.["T-Stat"] || "0",
                                            4
                                        ),
                                        4
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                A common threshold for P-value
                                                is 0.05. If the P-value is less
                                                than 0.05, we reject the null
                                                hypothesis and say that we found
                                                a statistically significant
                                                effect. In our app, this means
                                                having a Statistical Confidence
                                                greater than 95%.
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            Statistical Confidence (P-value)
                                            <AiTwotoneInfoCircle />
                                        </span>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    {toFixed(
                                        factorMetrics?.["P-Value"] || 0 * 100,
                                        4
                                    )}
                                    %
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                The signal is upward when stocks
                                                with the highest values of the
                                                indicator outperform stocks with
                                                the lowest values over the long
                                                term.
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            Signal Direction
                                            <AiTwotoneInfoCircle />
                                        </span>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    {factorMetrics?.Direction === 1 ? (
                                        <BsArrowUpRight />
                                    ) : (
                                        <BsArrowDownRight />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div>
                    <h5>Factor Info</h5>
                    {factorDetail?.Description ||
                        "„Under construction. An introduction of the factor/indicator/ratio will be added at this place.“"}
                </div>
            </>
        );
    }

    return <div>Loading...</div>;
};

export default FactorInfo;
