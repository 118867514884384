import { useState, useEffect } from "react";
import { useMessages } from "../../../hooks/useMessages";

// Typy pro Date a Date Range
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

// Hook pro práci s datumovým rozsahem
const useDateRange = (
    initialRange: [Date, Date] | undefined,
    onDateRangeChange: (newDateRange: [Date, Date] | undefined) => void
) => {
    const { addWarningMessage } = useMessages();
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedRange, setSelectedRange] = useState<
        [Date, Date] | undefined
    >(initialRange);
    const [formattedDateRange, setFormattedDateRange] = useState<string>("");

    // Funkce pro formátování datumu
    const formatDate = (date: Date): string => {
        return `${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    // Funkce pro parsování vstupního textu na datum
    const parseDate = (input: string): [Date, Date] => {
        const [startDateText, endDateText] = input
            .split("-")
            .map((text) => text.trim());

        if (!startDateText || !endDateText) {
            addWarningMessage(
                "Date Range Validations",
                "Input must contain two dates separated by ' - '."
            );
            throw new Error("Input must contain two dates separated by ' - '.");
        }

        const parseSingleDate = (dateText: string): Date => {
            const [monthText, yearText] = dateText.split("/");

            const month = parseInt(monthText, 10);
            const year = parseInt(yearText, 10);

            if (isNaN(month) || isNaN(year)) {
                addWarningMessage(
                    "Date Range Validations",
                    `Invalid date format: ${dateText}. Expected MM/YYYY`
                );
                throw new Error(`Invalid date format: ${dateText}`);
            }

            if (month < 1 || month > 12) {
                addWarningMessage(
                    "Date Range Validations",
                    `Month must be between 1 and 12: ${dateText}`
                );
                throw new Error(`Month must be between 1 and 12: ${dateText}`);
            }

            return new Date(year, month - 1);
        };

        const startDate = parseSingleDate(startDateText);
        const endDate = parseSingleDate(endDateText);

        if (startDate > endDate) {
            addWarningMessage(
                "Date Range Validations",
                "Start date cannot be later than end date."
            );
            throw new Error("Start date cannot be later than end date.");
        }

        return [startDate, endDate];
    };

    // Aktualizace formátovaného rozsahu dat
    useEffect(() => {
        if (!selectedRange) {
            setFormattedDateRange("");
            return;
        }

        const [startDate, endDate] = selectedRange;
        setFormattedDateRange(
            `${formatDate(startDate)} - ${formatDate(endDate)}`
        );
    }, [selectedRange]);

    useEffect(() => {
        if (
            initialRange &&
            selectedRange?.[0].toISOString() !==
                initialRange[0].toISOString() &&
            selectedRange?.[1].toISOString() !== initialRange[1].toISOString()
        ) {
            setSelectedRange(initialRange);
        } else if (!initialRange && selectedRange) {
            setSelectedRange(undefined);
        }
    }, [initialRange, selectedRange]);

    // Funkce pro změnu hodnoty z inputu
    const handleInputChange = (e: any) => {
        const text = e.target.value;
        setFormattedDateRange(text);
    };

    // Funkce pro validaci a změnu rozsahu dat po úpravě
    const handleInputBlur = (e: any) => {
        try {
            const text = e.target.value;
            const [startDate, endDate] = parseDate(text);
            setSelectedRange([startDate, endDate]);
            onDateRangeChange([startDate, endDate]);
        } catch (error: any) {
            setFormattedDateRange("");
            console.error(error.message);
        }
    };

    // Funkce volaná při výběru v kalendáři
    const handleCalendarSelect = (
        value: Value,
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (Array.isArray(value) && value[0] && value[1]) {
            const [startDate, endDate] = value;
            setSelectedRange([startDate, endDate]);
            onDateRangeChange([startDate, endDate]);
            setShowCalendar(false);
        }
    };

    return {
        selectedRange,
        showCalendar,
        setShowCalendar,
        formattedDateRange,
        handleInputChange,
        handleInputBlur,
        handleCalendarSelect,
    };
};

export default useDateRange;
